<template>
    <button :class="['btn-' + color, solid ? 'btn-solid' : '' ]"  @click="handleClickEvent" :disabled="showLoader">
        <slot name="default" v-if="!showLoader">Save</slot>
        <icon name="gridLoader" class="h-4 w-4 inline" v-else></icon>
    </button>
</template>

<script>
import Icon from "@/components/ui/Icon";

export default {
    components: { Icon },
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        color: {
            type: String,
            required: false,
            default: 'primary'
            // options are primary, success, transparent, danger
        },
        solid: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    emits: ['click'],
    data: () => {
        return {
            showLoader: false
        }
    },
    watch: {
        isLoading: {
            handler: function() {
                this.showLoader = this.isLoading
            },
            immediate: true
        }
    },
    methods: {
        handleClickEvent() {
            this.$emit('click');
        }
    }
}
</script>
