<template>
    <FinalForm ref="externalLinksForm" :initial-values="domainsforwarding" :submit="save" @change.self="handleFormChange">
        <template v-slot="props" v-if="this.domainsforwarding.customDomains">
            <form>
                <div class="bg-gray-100 p-4 mb-6">
                    <div class="form-row">
                        <div class="form-col">
                            <label for="email">staging domain *</label>
                            <p id="email" class="py-3">{{ domains.staging_url }}</p>
                            <span class="form-hint">The staging domain is set automatically.</span>
                        </div>
                        <div class="form-col">
                            <label for="phone">domain name *</label>
                            <input type="text" class="form-input" id="phone" v-model="domains.production_url" />
                            <span class="form-hint">This is the primary domain where the website will be accessed.</span>
                            <span v-if="hasError('domains.production_url')" class="form-error">{{ showError('domains.production_url') }}</span>
                        </div>
                    </div>
                </div>
                <div class="items-center mt-3 border border-yellow-200 bg-yellow-100 px-4 py-3" v-if="website.production_url && !website.is_domain_validated || website.production_url != domains.production_url">
                    <div class="flex items-center">
                        <icon name="alert" class="w-6 h-6 mr-2 text-yellow-200"></icon>
                        <p class="font-inter text-yellow-300 font-semibold">Production Domain is not configured.</p>
                    </div>
                    <p class="font-frank text-sm mt-2 pl-8">
                        Please create a CNAME with the NAME of <span class="font-mono font-semibold">{{ domains.production_url }}</span> 
                        and a VALUE of <span class="font-mono font-semibold">{{ domains.production_url }}.s3-website.us-east-2.amazonaws.com</span>
                    </p>
                    <div class="flex justify-between">
                        <div>
                            <button v-if="!isCheckingDomain" class="flex items-center ml-8 mt-4 bg-yellow-300 text-yellow-100 border-0 px-4 py-3 text-sm font-semibold" @click="checkDomain">
                                <icon name="checkmark" class="h-4 w-4 mr-2 text-yellow-200"></icon>
                                <span>Verify DNS Settings</span>
                            </button>
                            <p v-if="this.isCheckingDomain" class="text-sm ml-8 mt-4 font-semibold">Attempting to verify DNS...</p>
                        </div>
                    </div>
                </div>
                <div class="grid justify-items-end">
                    <div class="flex flex-row ">
                        <button
                            class="flex items-end border-b font-bold focus:outline-none button-color mr-10"
                            type="button"
                            v-on:click="isDomainForwardingSettings = !isDomainForwardingSettings"
                            >
                            domain forwarding
                        </button>
                        <button
                            class="flex items-end border-b font-bold focus:outline-none button-color "
                            type="button"
                            v-on:click="isCustomDnsSettings = !isCustomDnsSettings"
                            >
                            use custom dns settings
                        </button>
                    </div>
                </div>
                <div v-if="isDomainForwardingSettings">
                    <div class="flex flex-col flex-1 h-full px-2">
                        <div class="section-title text-base font-frank font-600 block mb-2">
                            Domain forwarding
                        </div>
                        <div  v-for="(customLink, index) in domainsforwarding.customDomains" :key="index">
                            <div class="bg-gray-100 p-4 mb-6 relative" v-if="customLink.key == 'redirect' || !customLink.key">
                                <div class="form-row" >
                                    <TextField :name="`customDomains[${index}].name`" label="domain" :validate="required"/>
                                    <p class="font-frank text-sm mt-2 pl-8 w-1/2">
                                        To redirect inputdomain to your website please add a <span class="font-frank font-600">{{customLink.name}}</span> record with the name <span class="font-frank font-600">{{domains.production_url}}</span> of
                                        your domain and put the website domain as content
                                    </p>
                                </div>
                                <icon name="closeCircle" @click="removeCustomLink(index)" class="w-4 h-4 text-gray-600 absolute top-1 right-1 cursor-pointer" />
                            </div>
                        </div>
                    </div>
                    <a href="#" class="text-link mb-2 items-center float-right"  @click.prevent="addCustomLink">
                        <icon name="plus" class="h-4 w-4 inline mr-2"></icon> Add domain forwarding
                    </a>
                </div>
                <modal-footer :disable-primary="isProcessing" :primary="props.handleSubmit"></modal-footer>
            </form>
        </template>
    </FinalForm>
</template>

<script>
    import Icon from "@/components/ui/Icon";
    import {resolveUrl} from "@/utils/String";
    import {mapActions, mapGetters} from "vuex";
    import QuextButton from "@/components/ui/QuextButton";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    // import CustomDnsForm from "@/components/cms/websites/CustomDnsForm";


    import NotifyMixin from "@/mixins/NotifyMixin";
    import TextField from "@/components/form/TextField";
    import {FinalForm} from "vue-final-form";
    import ValidatorMixin from "@/components/form/ValidatorMixin";

    export default {
        components: {ModalFooter, Icon, TextField, FinalForm },
        mixins: [ ModalNavigation, QuextButton, NotifyMixin, ValidatorMixin ],
        data: () => {
            return {
                website: {},
                domains: {
                    staging_url: null,
                    production_url: null,
                },
                isProcessing: false,
                isCheckingDomain: false,
                isValidated: false,
                isCustomDnsSettings: false,
                isDomainForwardingSettings: false,
                domainsforwarding: {
                    customDomains: [],
                },
                customLinkModel: {
                  name: null
                }
            }
        },
        computed: {
            ...mapGetters({
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                updateWebsiteInStore: 'websites/updateWebsite',
                notifySuccess: 'alerts/notifySuccess',
                notifyError: 'alerts/notifyError',
                clearWebsiteInformation: 'website_info/clearWebsiteInformation'
            }),
            checkDomain(){
                let domain = this.domains.production_url;
                let amazonUrl = this.domains.production_url + '.s3-website.us-east-2.amazonaws.com';
                this.isCheckingDomain = true;
                this.$cmsDataProvider.get('checkWebsiteDns', {websiteId: this.website.id,checkDnsDomain: domain, checkDnsTarget: amazonUrl})
                .then((response) => {
                    if(response.message){
                        this.isCheckingDomain = false;
                        this.notifyError(response.message);
                    }else{
                        this.notifySuccess('The domain DNS was validated successfully.');
                        this.isValidated = true;
                        this.isCheckingDomain = false;
                    }
                
                }).catch(() => {
                    this.isCheckingDomain = false
                    this.notifyError('There was an error validating the domain: '+this.domains.production_url);
                });
            },
            save(values) {
                if(this.validator.passes()) {
                    const payload = {};
                    payload.customers_id = this.website.customers_id;
                    payload.communities_id = this.website.communities_id;
                    payload.id = this.website.id;
                    payload.staging_url = resolveUrl(this.domains.production_url + '.s3-website.us-east-2.amazonaws.com');
                    payload.production_url = resolveUrl(this.domains.production_url);
                    payload.is_domain_validated = true;

                    const payloadDns = {}
                    payloadDns.custom_dns = values.customDomains.map(customLink => {
                        if (customLink.key == 'redirect' || !customLink.key){
                            customLink.key = 'redirect';
                            customLink.type = 'CNAME';
                            customLink.content = resolveUrl(this.domains.production_url);                          
                        }
                        return customLink;
                    });
                    this.$cmsDataProvider.create('createCustomDNS', { 
                        redirectDns: JSON.stringify(payloadDns.custom_dns), 
                        websiteId: this.website.id
                    })
                    .then(() => {
                        this.notifySuccess('the dns settings were saved successfully');
                        this.isSavingCustomDns = false;
                        this.getCustomDNS()
                        this.$emit('hideCustomSettings');
                    }).catch(() => {
                        this.notifyError('There was an error saving the information');
                        this.isSavingCustomDns = false;
                    });

                    this.isProcessing = true;
                    this.$cmsDataProvider.update('updateWebsite', { data: payload, id: this.website.id })
                    .then((response) => {
                        this.updateWebsiteInStore(response);
                        this.isProcessing = false;
                        this.notifySuccess('The domain was updated successfully');
                        this.redirect('websites.show', this.website.id);
                    }).catch(() => {
                        this.isProcessing = false;
                        this.notifyError('There was an error saving the information');
                    });
                }
            },
            addCustomLink() {
                this.$refs.externalLinksForm.finalForm.change("customDomains", [
                    ...this.domainsforwarding.customDomains,
                    structuredClone(this.customLinkModel)
                ]);
            },
            removeCustomLink(index) {
                this.$refs.externalLinksForm.finalForm.change("customDomains", [
                    ...this.domainsforwarding.customDomains.slice(0, index),
                    ...this.domainsforwarding.customDomains.slice(index + 1),
                ]);
            },
            handleFormChange({values}) {
                this.domainsforwarding = values;
            },
            loadWebsiteData() {
                this.website = this.getWebsiteInfo
                this.domains.staging_url = this.website.staging_url;
                this.domains.production_url = this.website.production_url;
                this.domainsforwarding.customDomains = this.website.custom_dns;
            },          
        },
        validations: {
         'domains.production_url': 'required | url',
        },
        async created() {
            this.initValidator();
            await this.loadWebsiteData();
        }
    }
</script>


<style scoped>
.button-color{
  border-color: var(--highlightColor500);
  color: var(--highlightColor500);
}
</style>